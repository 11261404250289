import React from 'react'
import { graphql, Link } from 'gatsby'
import Image from 'gatsby-image'
import PropTypes from 'prop-types'

import {
  Section,
  Hero,
  Container,
  Heading,
  Columns,
  Box
} from 'react-bulma-components'

import Layout from '../../components/layouts/index'
import SEO from '../../components/seo'

import '../styles/index.scss'

const apps = [
  {
    image: 'russianGuruLogo',
    name: 'RussianGuru',
    description:
      'With RussianGuru you can master the Russian cases by quizzing yourself on accusative, dative, genitive, prepositional, and instrumental cases.',
    link: '/apps/russian-guru'
  },
  {
    image: 'eyeTwentyLogo',
    name: 'EyeTwenty',
    description:
      'EyeTwenty app is a timer that reminds you to have a short 20 seconds break every 20 minutes.',
    link: '/apps/eye-twenty'
  },
  {
    image: 'snipperLogo',
    name: 'Snipper App',
    description:
      'Snipper App is a fast, native MacOS snippets manager with modern, and lightweight GUI with syntax highlighting made for developers by the developer.',
    externalLink: true,
    link: 'https://snipper.app?ref=teologov.com'
  }
]

class AppsPage extends React.Component {
  getImage(imageName) {
    return this.props.data[imageName].childImageSharp.fluid
  }

  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout
        location={this.props.location}
        title={siteTitle}
        className="is-paddingless"
        bodyInContainer={false}
        alignBody="flex-start"
        navbarIsFixed
      >
        <SEO
          title="Apps made by Andrey Teologov"
          url={this.props.location.href}
        />
        <Section className="is-paddingless">
          <Hero color="primary" gradient>
            <Hero.Body>
              <Container>
                <Heading size={2} className="is-spaced">
                  Apps made by me
                </Heading>
                <Heading subtitle size={5} renderAs="h2">
                  Last year I started working on my own desktop and mobile apps.
                  <br className="is-hidden-small-screen" />
                  {`It's a completely new field for me, and I do my best to
                  deliver high-quality applications.`}
                  <br className="is-hidden-small-screen" />
                  Check them out! &darr;
                </Heading>
              </Container>
            </Hero.Body>
          </Hero>
        </Section>
        <Section>
          <Hero.Body>
            <Container>
              <Columns breakpoint="tablet">
                <React.Fragment>
                  {apps.map((app, key) => (
                    <React.Fragment key={key}>
                      <Columns.Column
                        tablet={{
                          size: 'half'
                        }}
                        desktop={{
                          size: 'one-third'
                        }}
                      >
                        <Box>
                          <Image fluid={this.getImage(app.image)} />
                          <Heading
                            size={3}
                            subtitle
                            className="is-spaced"
                            renderAs="h2"
                          >
                            {app.externalLink ? (
                              <a
                                href={app.link}
                                target="_blank"
                                rel="noopener noreferrer"
                                itemProp="name"
                              >
                                {app.name}
                              </a>
                            ) : (
                              <Link to={app.link} itemProp="name">
                                {app.name}
                              </Link>
                            )}
                          </Heading>
                          <p className="subtitle is-6" itemProp="description">
                            {app.description}
                          </p>
                        </Box>
                      </Columns.Column>
                    </React.Fragment>
                  ))}
                </React.Fragment>
              </Columns>
            </Container>
          </Hero.Body>
        </Section>
      </Layout>
    )
  }
}

AppsPage.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object
}

export default AppsPage

export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fluid(maxWidth: 1000) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
`

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    russianGuruLogo: file(
      absolutePath: { regex: "/russian-guru-white-back.png/" }
    ) {
      ...fluidImage
    }
    eyeTwentyLogo: file(absolutePath: { regex: "/eye-twenty.png/" }) {
      ...fluidImage
    }
    snipperLogo: file(absolutePath: { regex: "/snipper.png/" }) {
      ...fluidImage
    }
  }
`
